import { ref, computed, watch } from '@vue/composition-api'
// import { title } from '@core/utils/filter'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment-timezone'
import store from '@/store'
import router from '@/router'
import ability from '@/libs/acl/ability'
import { debounce } from 'lodash'

export default function useList() {
    const refListTable = ref(null)

    const toast = useToast()

    store.dispatch('plate_list/getAll')
    store.dispatch('tenant/getAll')

    const listOptions = computed(() => store.getters['plate_list/getSelectedType'])
    const listFilter = ref(null)
    const tenantOptions = computed(() => store.getters['tenant/getSelectedItems'])
    const tenantFilter = ref(null)


    if (router.currentRoute.params.type)
        listFilter.value = parseInt(router.currentRoute.type)

    const tableColumns = [
        { label: 'actions.name', key: 'actions' },
        { label: 'plate_list.name', key: 'name', sortable: true },
        { label: 'tenants.title', key: 'tenants', sortable: false, formatter: (value, key, item) => {
            if(item.tenants) return [...new Set(item.tenants.map(item => { return item.name}))].join(', ')
            return ""
            }
        },
        { label: 'plate_list.list_type', key: 'type', sortable: true },
        // { label: 'plate_list.colour', key: 'color', sortable: true },
        // { label: 'plate_list.description', key: 'description', sortable: true },
        // { label: 'plate_list.renew_items_expiration', key: 'renewItemsExpiration', sortable: true },
        { label: 'date.created_at', key: 'created_at', sortable: true, formatter: 'formatDateAssigned' },
    ]

    const perPage = ref(10)
    const totalList = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)

    const dataMeta = computed(() => {
        const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalList.value,
        }
    })

    const fetchList = (ctx, callback) => {
        store.dispatch('plate_list/getAll', {
            q: searchQuery.value,
            max: perPage.value,
            'page[n]': currentPage.value,
            sortBy: sortBy.value,
            sortDesc: isSortDirDesc.value,
            type: listFilter.value,
            id_tenant: tenantFilter.value,
        })
            .then(response => {
                callback(response.data)
                totalList.value = response.meta.total
            })
            .catch(() => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error obteniendo la información',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
            })
    }

    const formatDateAssigned = value => {
        const formattedDate = moment(value).format('DD/MM/YYYY HH:mm:ss')
        return formattedDate
    }

    const refetchData = () => {
        refListTable.value.refresh()
    }

    watch([listFilter, currentPage, perPage, ], () => {
        refetchData()
    })

    watch([ searchQuery ], debounce(() => {
      refetchData()
    }, 500))

    return {
        tableColumns,
        perPage,
        currentPage,
        totalList,
        fetchList,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refListTable,
        formatDateAssigned,

        listOptions,
        listFilter,

        refetchData,
        tenantOptions,
        tenantFilter
    }
}
